import {
  CommonModule,
  IMAGE_LOADER,
  ImageLoaderConfig,
  NgOptimizedImage,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';

import { RrsMediaComponent } from '@app/custom/features/rrs-media/components/rrs-media/rrs-media.component';

@NgModule({
  declarations: [RrsMediaComponent],
  exports: [RrsMediaComponent],
  imports: [CommonModule, NgOptimizedImage, I18nModule],
  providers: [
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig): string => {
        const height =
          config.src.indexOf('deichmann.scene7.com') > 0 &&
          config.width &&
          config?.loaderParams
            ? (+config.loaderParams.height * config.width) /
              +config.loaderParams.width
            : 0;
        return config.src.indexOf('deichmann.scene7.com') > 0 && config.width
          ? config.src + '&wid=' + config.width + '&hei=' + Math.trunc(height)
          : config.src;
      },
    },
  ],
})
export class RrsMediaModule {}
